<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('External Links')}}</h2>
      </div>
      <div class="vx-col w-full lg:w-1/2 items-end">
        <vs-alert class="mb-base" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
          {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
        </vs-alert>
        <div v-else>
          <div class="flex justify-between">
            <span class="font-semibold"></span>
            <span class="font-medium text-primary cursor-pointer">
              <vs-dropdown
                vs-trigger-click
                class="dropdown dd-actions cursor-pointer mr-4 mb-4"
              >
                <vs-button
                  color="primary"
                  icon-pack="feather"
                  icon="icon-plus"
                  size="large"
                  :disabled="userData.is_self_signup && userData.name === 'Entrepreneur Merchant'"
                >
                </vs-button>
                <vs-dropdown-menu v-show="!userData.is_self_signup || userData.name !== 'Entrepreneur Merchant'" class="add-shipment-dropdown">
                  <vs-dropdown-item @click="addLink('magic')">
                    <span class="flex items-center">
                      <feather-icon
                        icon="LinkIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Generate Magic Link')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="addLink('message')">
                    <span class="flex items-center">
                      <feather-icon
                        icon="MessageSquareIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Send Individual Message')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="bulkModal = true">
                    <span class="flex items-center">
                      <feather-icon
                        icon="UploadIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Upload Multiple SMS')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </span>
          </div>
        </div>
      </div>
    </div>
    <vs-tabs v-model="activeTab" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
      <vs-tab icon-pack="feather" icon="icon-link" :label="!isSmallerScreen ? $t('Magic Links') : ''">
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <magic-links :merchantStatus="merchantStatus" :magicLinkModal="magicLinkModal" />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-message-square" :label="!isSmallerScreen ? $t('Outgoing Messages') : ''">
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <outgoing-messages :magicLinkModal="magicLinkModal" :bulkModal="bulkModal"/>
        </div>
      </vs-tab>
    </vs-tabs>
    <add-magic-link :magicLink="magicLink" :type="type" :magicLinkModal="magicLinkModal" @magicLinkModal="magicLinkModal = $event" @generateMagicLink="generateMagicLink"/>
    <add-bulk-sms :magicLink="magicLink" :bulkModal="bulkModal" @bulkModal="bulkModal = $event" @sendBulkSMS="sendBulkSMS"/>
  </div>
</template>

<script>
import MagicLinks from './MagicLinks.vue'
import OutgoingMessages from './OutgoingMessages.vue'
import AddBulkSms from './components/AddBulkSms.vue'
import AddMagicLink from './components/AddMagicLink.vue'
import { sendRequest } from '../../http/axios/requestHelper'
import i18nData from '../../i18n/i18nData'
import readXlsxFile from 'read-excel-file'
import writeXlsxFile from 'write-excel-file'
import common from '../../assets/utils/common'

export default {
  data () {
    return {
      activeTab: 0,
      tabsDic: {'0': 'magic-links', '1': 'outgoing-messages'},
      tabsNameDic: {'magic-links': 0, 'outgoing-messages': 1},
      magicLinkModal: false,
      magicLink: {},
      merchantID: '',
      type: '',
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      bulkModal: false,
      fileUploaded: '',
      merchantStatus: '',
      userData: {}
    }
  },
  watch: {
    activeTab () {
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: 1
        }
      }).catch(() => {})
    }
  },
  methods: {
    addLink (type) {
      this.type = type
      this.magicLinkModal = true
    },
    generateMagicLink () {
      if (this.type === 'magic') {
        this.magicLink.max_usage = Number(this.magicLink.max_usage)
        this.magicLink.expiry_date = this.magicLink.expiry_date ? new Date(this.magicLink.expiry_date).toISOString().split('T')[0] : ''
        sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/magic-link/`, 'post', this.magicLink, true,
          () => {
            if (this.releaseVersion !== 'staging') {
              this.$mixpanel.track('MAGIC_LINK_CREATED', {
                merchant_name: this.merchant.name,
                merchant_email: this.userMerchant.email,
                source: /(android)/i.test(navigator.userAgent)
              })
            }
          }
        )
        this.activeTab = 0
        this.$router.push({
          query: {
            page: 1,
            tab: 'magic-links'
          }
        }).catch(() => {})
        this.magicLinkModal = false
      } else {
        sendRequest(false, false, this, `api/v1/magic/sms/?merchant_id=${this.merchantID}`, 'post', this.magicLink, true,
          () => {
            this.activeTab = 1
            this.$router.push({
              query: {
                page: 1,
                tab: 'outgoing-messages'
              }
            }).catch(() => {})
            this.magicLinkModal = false
          }
        )
      }
      this.type = ''
      this.magicLink = {}
    },
    sendBulkSMS (file) {
      const promises = []
      readXlsxFile(file).then((rows) => {
        rows = rows.slice(1)
        rows.forEach(row => {
          promises.push(this.sendSMS(row))
        })
        Promise.allSettled(promises)
          .then((results) => {
            if (results.filter(item => item.status === 'rejected').length > 0) {
              const HEADER_ROW = [
                {
                  value: 'Name',
                  fontWeight: 'bold'
                },
                {
                  value: 'Phone Number',
                  fontWeight: 'bold'
                },
                {
                  value: 'Error',
                  fontWeight: 'bold'
                }
              ]
              const data = [HEADER_ROW]
              const errorsData = results.filter(item => item.status === 'rejected')
              errorsData.forEach(item => {
                data.push([
                  {
                    type: String,
                    value: item.reason.data[0]
                  }, 
                  {
                    type: String,
                    value: item.reason.data[1]
                  },
                  {
                    type: String,
                    value: `${item.reason.error.message  } Note: Add number after +20`
                  }
                ])
              })
              writeXlsxFile(data, {
                fileName: 'errors.xlsx'
              })
              this.$vs.notify({
                color: 'warning',
                title: i18nData[this.$i18n.locale]['Success'],
                text: i18nData[this.$i18n.locale]['All successful data has been sent, and failed data has been downloaded into another file.'],
                position: 'top-center'
              })
            } else {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['All SMS has been added successfully!'],
                position: 'top-center'
              })
            }
            this.activeTab = 1
            this.$router.push({
              query: {
                page: 1,
                tab: 'outgoing-messages'
              }
            }).catch(() => {})
            this.bulkModal = false
          })
          .catch(() => {
          })
      })
    },
    sendSMS (sms) {
      const padWithLeadingZeros = (num, count) => {
        const char = '0'
        const number = char.repeat(count) + num
        return number
      }
      return new Promise(async (resolve, reject) => {
        try {
          const smsBody = {
            name: sms[0],
            phone: sms[1] ? String(sms[1]).startsWith('0') ? sms[1] : padWithLeadingZeros(sms[1], 1) : ''
          }
          sendRequest(false, false, this, `api/v1/magic/sms/?merchant_id=${this.merchantID}`, 'post', smsBody, true,
            () => {
              resolve({
                status: 200
              })
            },
            (error) => {
              reject({
                status: 400,
                data: sms,
                error: error.response.data
              })
              this.$vs.loading.close()
            }
          ) 
        } catch (error) {
          reject({
            status: 400,
            error: {}
          })
        }
      })
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 500
    }
  },
  components: {
    MagicLinks,
    OutgoingMessages,
    AddBulkSms,
    AddMagicLink
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.userMerchantData
      this.userData = results.merchantData
      this.merchantID = user.merchant
      this.merchantStatus = this.userData.status
    })
  }
}
</script>